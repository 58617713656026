(function () {
  let resetTrigger = 256;
  let temp = 0;
  let animated = false;
  let animationDelay = 200;

  const scrollDownhandler = () => {
    if (animated) return;
    navbar.classList.add('sgbs-navbar-interactive-animated');

    setTimeout(() => {
      navbar.classList.add('border-bottom');
    }, animationDelay / 2);
    animated = true;
  };

  const scrollTopHandler = () => {
    if (!animated) return;
    navbar.classList.remove(
      'sgbs-navbar-interactive-animated',
      'border-bottom',
    );
    animated = false;
  };

  const navbar = document.querySelector('.sgbs-navbar-interactive');
  if (!navbar) return;

  document.addEventListener(
    'scroll',
    () => {
      const scrollLevel = document.documentElement.scrollTop;

      if (scrollLevel > temp) {
        scrollDownhandler();
      } else if (scrollLevel < resetTrigger) {
        scrollTopHandler();
      }
    },
    true,
  );
})();
